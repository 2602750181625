// import "./VideoComp.css"
import React, { useEffect } from "react"
import Style from './video.module.scss'

function VideoComp({ video }) {
useEffect(()=>{
  function enableAutoplay() { 
    document.querySelector("video").play();
  }
  enableAutoplay()
},[])
  
  return (
    

      <div className={Style.video_camp}>
        <div className={Style.video_container}>
          <video className={Style.video} src={video} autoplay controls></video>

          </div>
      </div>

    



  )
}
export default VideoComp