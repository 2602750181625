import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../assets/theme6/worm-line-loading.json'; // Replace with the path to your JSON animation file


const CountAnimation = () => {
    const container = useRef(null);
  
    useEffect(() => {
      if (container.current) {
        lottie.loadAnimation({
          container: container.current,
          renderer: 'svg', // Choose the renderer: svg, canvas, html (svg is recommended for performance)
          loop: true,
          autoplay: true,
          animationData: animationData // Your JSON animation data
        });
      }
    }, []);
  
    return <div ref={container} />;
  };
  
  export default CountAnimation;
  