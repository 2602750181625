import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../assets/theme6/Party Confetti.json'; // Replace with the path to your JSON animation file
import SuccessImg from '../../../../assets/theme6/successful.png'

const SuccessAnimation = () => {
    const container = useRef(null);
  
    useEffect(() => {
      if (container.current) {
        lottie.loadAnimation({
          container: container.current,
          renderer: 'svg', // Choose the renderer: svg, canvas, html (svg is recommended for performance)
          loop: true,
          autoplay: true,
          animationData: animationData // Your JSON animation data
        });
      }
    }, []);
  
    return  <div ref={container} />
   
            
  };
  
  export default SuccessAnimation;
  