import axios from "axios";


const authAxios = axios.create({
    // baseURL: "http://localhost:4000",  //local 
    // baseURL:"https://wall.api.quiz.khiladiaddasupport.com", //qa tests
    baseURL:"https://prod.quizwall.io", //prod

    
    headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('pwa_token'))}`,
    }
});


authAxios.interceptors.response.use((config) => {

    return config;

}, (error) => {

    let { response: { data: { status } } } = error

    // unauthrised user
    if (status == 401) {
        // localStorage.removeItem('pwa_token') 
       
        localStorage.clear();
    }
    return Promise.reject(error);
});




export default authAxios; 