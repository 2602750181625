// import "./RadioComp.css";
import React, { useEffect, useState } from "react";
import Style from './radio.module.scss'

function Radio({ getRadioData, options, setOptions, setScrollBottom, result, setResult, nextClickHandler }) {

    const [checkData, setCheckData] = useState(getRadioData)

    const [select, setSelect] = useState(0)
    const [block,setBlock]=useState(true)




    // function choseChangeHandler(event) {
    //     console.log(event.target.value,"event.target.value")
    //     setOptions(event.target.value)
    //     setSelect(1)
    //     setScrollBottom(true)

    // }



    function choseChangeHandler(event) {
        if(block){
        const value = event.target.getAttribute('data-value');
        setOptions(value)

        
        setSelect(1)
        setScrollBottom(true)
        nextClickHandler(value)
        }
        setBlock(false)

    }

    








    return (
        <>
            <div className={Style.input_radio}>
                {/* <div className={Style.qus_heading} style={{ margin: "15px 0px", fontSize: "12px", fontWeight: "400", height: "100%", scrollY: "auto",color:"#fff" }}>Choose the correct answers: </div> */}

                {checkData?.length > 0 ? (checkData.map((item, index) => {
                    return (

                        // <div style={{ marginTop: "15px" }} key={index}>
                        //         <table style={{ background: result ==2&&options==item.option ? "#21CF60" : result == 3&&options==item.option? "#FA5743" : "rgba(142, 84, 233, 0.17)", padding: "10px 10px", borderRadius: "14px",border:select==1&&options==item.option?"2px solid white":"0px solid black"}}>
                        //         <label htmlFor={item.option}>

                        //             <td style={{width:"5vw"}}>
                        //                 <input style={{appearance: "none",verticalAlign: "middle",display:'relative',left:"100px"}} type="radio" id={item.option} name="mode" value={item.option} onChange={choseChangeHandler} />
                        //             </td>
                        //             <td style={{width:"100vw",marginLeft:""}} >
                        //                 <label style={{ fontWeight: "600", fontSize: "18px",margin:"0px",padding:"0px",color:"#fff"}} htmlFor={item.option}>{item.option}</label>
                        //             </td>

                        //         </label>
                        //     </table>
                        //     {/* <br /> */}
                        // </div>

                        <div >
                          

                            <div key={index} style={{ background: result == 2 && options == item.option ? "#21CF60" : result == 3 && options == item.option ? "#FA5743" : "rgba(142, 84, 233, 0.17)", marginTop: "10px", color: "#fff", padding: "10px 20px", borderRadius: "14px", border: select == 1 && options == item.option ? "2px solid white" : "0px solid black" }} id={item.option} data-value={item.option} onClick={choseChangeHandler}>{item?.option}</div>

                        </div>



                    )


                })) : ("No Category Available")}
            </div>

        </>
    );
}
export default Radio;
