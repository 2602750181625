import React, { useState,useEffect } from 'react'
import Style from './layout.module.scss'
import Logo from '../../../../assets/theme6/quizwall.png'
import ExitPopup from './component/ExitPopup';



const Layout = ({ children,missionId }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [count, setCount] = useState(3)

  useEffect(() => {
    const intervalId = setInterval(() => {
        if (count > 0) {
            setCount(count - 1); // Decrease count by 1
        }else {
            clearInterval(intervalId); // Clear the interval when count reaches 1
        }
    }, 1000); // Repeat every 1000 milliseconds (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
}, [count]);


  return (
    <div className={Style.container}>
      <div className={Style.logo}>
        <div style={{marginLeft:"5%"}}>
          <img src={Logo} width={"130px"} alt="logo" />
        </div>
        {/* <div style={{marginRight:"5%"}}>
          {!count &&<span style={{ fontSize: "2rem" }} onClick={() => { setShowPopup(true) }}>✖</span>}
        </div> */}
      </div>
      {children}
      {showPopup && <ExitPopup open={showPopup} setOpen={setShowPopup} missionId={missionId} />}

    </div>
  )
}

export default Layout