import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './theme1.scss'
import reportWebVitals from './reportWebVitals';


var val=Number(JSON.parse(localStorage.getItem('pwa_app_theme'))) || 1






const importCSSConditionally = async() => {
  if (val===1) {
    await import('./theme1.scss');
  }else if(val===2){
    await import('./theme2.scss');  
  }else if(val===3){
    await import('./theme3.scss');  
  }
  else if(val===4){
    await import('./theme4.scss'); 
  }
  else if(val===5){
    await import('./theme5.scss'); 
  }
  else if(val===6){
    await import('./theme6.scss'); 
  }
};

importCSSConditionally()


  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <App />
  </>
);


reportWebVitals();
