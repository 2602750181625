import React from 'react'
import Maintinance from '../../assets/img/work.gif'

const index = () => {
  return (
    <div className='maintinance__parent'>
        <h2>Maintenance Mode</h2>
        <img src={Maintinance} alt="maintinance_image" />
        <h3>We'll be back shortly! Please stay tuned, and thank you for your patience.</h3>
    </div>
  )
}

export default index