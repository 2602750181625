import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryAction } from '../../../../redux/screen/category';
import { useNavigate } from 'react-router-dom'
import LoadingUI from '../../../../comman/LoadingUI'
import Style from '../style/categories.module.scss'
import Skeleton from '@mui/material/Skeleton';






function Categories() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const category = useSelector(state => state.getCategory)
    const { data: CategoryData, loading: CategoryLoading,status,message } = category
    const getAppSelector = useSelector(state => state.getApp)
    const {data:{is_ads_enabled}}=getAppSelector

    if(status===401){
        alert(message)
    }

    

    useEffect(() => {
        dispatch(getCategoryAction())
    }, [dispatch])

    return (
        <>
            
            <div className={Style.theme__banner}>

                <p>Quiz Genre</p>
                

            </div>
            <div className={Style.categories_sec}>

                {CategoryLoading ? <>
                <Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 

/>

<Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 

  
/>


<Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 

  
/>

{/* 
<Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  
/> */}

</> : CategoryData?.length > 0 ? (CategoryData.map((item, index) => {
                    return (
                        <>
                        <div key={item?._id}  className={Style.categorie} onClick={() => { navigate('/sub-category', { state: { cat_id: item._id, catname: item.cat_name } }) }}>
                            <div>
                                <div className={Style.categorie_img}>
                                    <center><img src={item.image}  alt="img" /></center>
                                </div>
                            </div>
                            <div>
                                <p className={Style.categorie_p} style={{fontSize:"0.9rem"}}>
                                    {item.cat_name}
                                </p>
                            </div>
                            <div>
                                <button className={Style.category_button} onClick={() => { navigate('/sub-category', { state: { cat_id: item._id, catname: item.cat_name } }) }}>
                                    View
                                </button>
                            </div>
                            

                        </div>
                        {index%2!==0 && is_ads_enabled && <div className={Style.categorie}>
                                ads
                            </div>}
                        
                        </>)
                })) : (<>
                <Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 


/>

<Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 

  
/>


<Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  style={{ borderRadius: '80% 80% 20% 20%' }} 

  
/>


{/* <Skeleton
//   sx={{ bgcolor: 'grey.900' }}
  variant="rectangular"
  width={350}
  height={118}
  
/> */}

</>)}

            </div>
        </>
    )
}

export default Categories