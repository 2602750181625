// import "./ImageComp.css"
import React,{useEffect, useState} from "react"
import { useNavigate } from 'react-router-dom';
import Style from './image.module.scss'
import LoadingUI from '../../../../../comman/LoadingUI';
import Skeleton from '@mui/material/Skeleton';




function ImageComp({image}) {
  const [loaded, setLoaded] = useState(false);
  // console.log('checking image ===>', image)

  const handleImageLoad = () => {
    setLoaded(true);
  };

  


  return (
    <div class={Style.image_div}>
      {!loaded && <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />}
     <img class={Style.qns_image}  src={image} style={{ display: loaded ? 'block' : 'none' }} alt="question_image" onLoad={handleImageLoad}/>
    </div>
  )
}
export default ImageComp