import React, { useEffect } from 'react'
// import styles from '../../../css/screens/Home.module.scss'
// import styles from '../../../css/theme1.module.scss'
import CatImg from '../../../assets/img/categories.png'
import Car from '../../../assets/img/car.png'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material';
import { getCategoryAction } from '../../../redux/screen/category';
import { useNavigate } from 'react-router-dom'
import LoadingUI from '../../../comman/LoadingUI'






function Category() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const category = useSelector(state => state.getCategory)
    const { data: CategoryData, loading: CategoryLoading,status,message } = category
    const getAppSelector = useSelector(state => state.getApp)
    const {data:{is_ads_enabled}}=getAppSelector

    if(status===401){
        alert(message)
    }

    // console.log("CategoryData-->", CategoryData)
    

    useEffect(() => {
        dispatch(getCategoryAction())
    }, [dispatch])

    return (
        <>
            <div className="banner">

                {/* <img className={styles.banner_img_top} src={CatImgT} /> */}
                <img src={CatImg} style={{zIndex:"1"}}/>


            </div>
            <div className="theme__banner">

                {/* <img className={styles.banner_img_top} src={CatImgT} /> */}
                <p>Categories</p>
                

            </div>
            <div className="categories_sec">

                {CategoryLoading ? <LoadingUI /> : CategoryData?.length > 0 ? (CategoryData.map((item, index) => {
                    return (
                        <>
                        <div key={item?._id}  className="categorie" onClick={() => { navigate('/sub-category', { state: { cat_id: item._id, catname: item.cat_name } }) }}>
                            <div>
                                <div className="categorie_img">
                                    <center><img src={item.image}  alt="img" /></center>
                                </div>
                            </div>
                            <div>
                                <p className="categorie_p" style={{fontSize:"0.9rem"}}>
                                    {item.cat_name}
                                </p>
                            </div>
                            <div>
                                <button className="category_button" onClick={() => { navigate('/sub-category', { state: { cat_id: item._id, catname: item.cat_name } }) }}>
                                    View
                                </button>
                            </div>
                            

                        </div>
                        {index%2!==0 && is_ads_enabled && <div className="categorie">
                                ads
                            </div>}
                        
                        </>)
                })) : ("No Category Available")}

            </div>
        </>
    )
}

export default Category