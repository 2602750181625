import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Style from './play.module.scss'
import Radio from './component/Radio'
import Check from './component/Check'
import Image from './component/Image'
import Audio from './component/Audio'
import Video from './component/Video'
import CryptoJS from 'crypto-js';
import { useNavigate, useLocation } from 'react-router-dom'
import { submitAction } from "../../../../redux/screen/submit";
import Wrong from '../../../../assets/theme6/wrong.png'
import Right from '../../../../assets/theme6/right.png'
import Timer from './Timer'
import TimeOut from './TimeoutPopup'
import ExitPopup from './component/ExitPopup';







const Play = ({ timer, time_criteria, reward,qnaData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location =useLocation()



    const [data, setData] = useState([])
    const [options, setOptions] = useState([])
    const [scrollBottom, setScrollBottom] = useState(false)
    const [submitAnswer, setSubmitAnswer] = useState([])
    const [second, setSecond] = useState(false);
    const [timeTaken, setTimeTaken] = useState(0);
    const [flag, setFlag] = useState(true)
    const [submitDisable, setSubmitDisable] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [missionId, setMissionId] = useState(null)
    const [result, setResult] = useState(1)  /* 1=neutral 2=right 3=wrong */
    const [count, setCount] = useState(0)
    const [num, setNum] = useState(1)
    const [isBack, setIsBack] = useState(false);


    const [timerCompleted, setTimerCompleted] = useState(false);
    const [remainTime, setRemainTime] = useState(time_criteria * 60)
    const [showPopup, setShowPopup] = useState(false);




    useEffect(() => {

        const intervalId = setInterval(() => {


            if (remainTime > 0) {
                // console.log(remainTime, "remainTime");
                setRemainTime(prev => prev - 1)
            } else {
                setTimerCompleted(true);
                // console.log("Timer completed!");
                clearInterval(intervalId)
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [remainTime]);


// useEffect(() => {
       

//         window.location.hash ="no-back-button";
//             window.location.hash = "Again-No-back-button";
//             window.onhashchange = function () {
//                 window.location.hash = "no-back-button";
//             }
        
//     }, [])


    useEffect(() => {
        setInterval(() => {
            setTimeTaken(prev => prev + 1)
        }, 1000);

        //   return () => {
        //     second
        //   }
    }, [])


    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          // Custom logic to handle the refresh
          // Display a confirmation message or perform necessary actions
          alert("hello world")
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);


    

    useEffect(() => {

        const secretKey = process.env.REACT_APP_MY_SECRET_KEY
        const decryptedBytes = CryptoJS.AES.decrypt(qnaData, secretKey);
        const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));


        setMissionId(decryptedData?._id)

        setData(decryptedData?.link_qns)

    }, [])

    const next = () => {
        setTimeout(() => {
            setOptions([])
            setCount(count + 1);

        }, 1000);

    }

    function nextClickHandler(value) {

        if (num === data.length) {
            submitHandler(value)
        } else {
            setNum(prev => prev + 1)

            let matched;
            // console.log("data[count]?.answer_type ==>",data[count]?.answer_type )
            if (data[count]?.answer_type == 'MCQ') {

                matched = options.every(option =>
                    data[count]?.options.some(item => item.option === option && item.correct === true)
                );

            } else {

                // console.log(options,"options")
                matched = data[count]?.options.some(item => item.option === value && item.correct === true);


            }

            // console.log("matched-->",matched)
            if (matched) {
                setCorrectAnswer(correctAnswer + 1)
                setResult(2)
                setTimeout(() => {
                    setResult(1)
                }, 1000);
            } else {
                setResult(3)
                setTimeout(() => {
                    setResult(1)
                }, 1000);
            }

            // submitAnswer.push({ _id: data[count]?._id, option: options })
            next();
            setSecond(true);


        }
    }

    // useEffect(() => {
    //     console.log(hello);
    // }, [correctAnswer])


    const submitHandler = async (value) => {
        // submitAnswer.push({ _id: data[count]?._id, option: options })

        let totalCorrectAnswer = correctAnswer
        let matched;

        if (data[count]?.answer_type == 'MCQ') {
            matched = options.every(option =>
                data[count]?.options.some(item => item.option === option && item.correct === true)
            );

        } else {

            matched = data[count]?.options.some(item => item.option === value && item.correct === true);


        }


        if (matched) {

            totalCorrectAnswer++;
            setResult(2)
            setTimeout(() => {
                setResult(1)
            }, 1000);


        } else {
            setResult(3)
            setTimeout(() => {
                setResult(1)
            }, 1000);
        }

        const secretKey = process.env.REACT_APP_MY_SECRET_KEY
        const dataObj = { "mission_id": missionId, "timeTaken": timeTaken, "correctAnswer": totalCorrectAnswer };
        // console.log("dataObj-->", dataObj);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataObj), secretKey).toString();

        setTimeout(async () => {
            const submitDataRes = await dispatch(submitAction({ encryptedData: encryptedData }))
            if (submitDataRes?.payload?.status === 201) {

                navigate(`/mission-success?reward=${reward}`)
            }
            else {
                navigate(`/mission-unsuccess?mission_id=${missionId}`)

            }
        }, 1000)





        // const submitDataRes = await dispatch(submitAction({ "mission_id": mission_id, "correctAnswer": correctAnswer, "timeTaken": timeTaken }))





    }



    useEffect(() => {
        if (scrollBottom) {

            window.scrollTo(0, 10000)
            setScrollBottom(false)
        }
    }, [scrollBottom])

    useEffect(() => {
        let prevLocation = location.pathname;
        let prevHistoryLength = window.history.length;
        const handlePopState = () => {
          const currentLocation = location.pathname;
          const currentHistoryLength = window.history.length;
          if (currentHistoryLength < prevHistoryLength || currentLocation !== prevLocation) {
            // setShowPopup(true)
            setIsBack(true);
          } else {
            // console.log("hello popup1")
            setShowPopup(true)


            setIsBack(false);
          }
          prevLocation = currentLocation;
          prevHistoryLength = currentHistoryLength;
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [location]);


    // useEffect(() => {
    //     setTimeout(()=>{
    //         window.location.hash = window.location.hash+"no-back-button";
    //         window.location.hash = "Again-No-back-button";
    //         window.onhashchange = function () {
    //             window.location.hash = "no-back-button";
    //         }
    //     },4000)

    // }, [])

    return (

        <>
            {/* <div> */}

            <div className={Style.circular__bar}>
                {timer}
            </div>
            <div className={Style.container} key={data[count]?._id}>
                <div className={Style.Qus__area}>


                    <div className={Style.count__number}>
                        <p>Q.{count + 1}</p>
                    </div>

                    <div className={Style.shadow__area__q__img} /*  className={`${(data[count]?.type === 'image' || data[count]?.type === 'video') ? `${Style.shadow__area__q__img}`:`${Style.shadow__area__q}`}`}*/>
                        <div className={Style.main__area__q}>


                            <div className={Style.question__area}>
                                <div style={{ display: "flex",flexDirection:"column",gap:"10px",alignItems: "center", justifyContent: "center",position: "relative" }}>


                                    {<p style={{ textAlign: "center", opacity: result !== 1 ? 0.1 : 1 }}>{data[count]?.question}</p>}
                                    {result == 2 && <div className={Style.wrong}>
                                        <img src={Right} width={"150px"} alt="right" />
                                    </div>}
                                    {result == 3 && <div className={Style.right}>
                                        <img src={Wrong} width={"150px"} alt="wrong" />
                                    </div>}

                                    {<div className={Style.file__area} style={{ opacity: result !== 1 ? 0.1 : 1 }}
                                    >
                                        {data[count]?.type === 'image' && <Image image={data[count]?.file_url} />}
                                        {data[count]?.type === 'audio' && <Audio audio={data[count]?.file_url} />}
                                        {data[count]?.type === 'video' && <Video video={data[count]?.file_url} />}
                                    </div>}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className={Style.Ans__area}>

                    <div className={Style.shadow__area}>
                        <div className={Style.main__area}>


                            {data[count]?.answer_type == 'optional' ? (
                                <Radio getRadioData={data[count]?.options} options={options} setOptions={setOptions} setScrollBottom={setScrollBottom} result={result} setResult={setResult} nextClickHandler={nextClickHandler} />
                            ) : (
                                <Check getCheckBoxesData={data[count]?.options} options={options} setOptions={setOptions} setScrollBottom={setScrollBottom} result={result} setResult={setResult} />
                            )}

                            {/* {
                                options.length !== 0 && flag && (data.length - 1 > count ? (
                                    <div>
                                        <div className="pink-button" onClick={nextClickHandler}>
                                            next
                                        </div>

                                    </div>
                                ) : (
                                    <div>
                                        <button className="pink-button" disabled={submitDisable} onClick={() => { setSubmitDisable(true); submitHandler() }}>submit</button>

                                    </div>
                                ))} */}
                        </div>
                    </div>
                </div>
            </div>

            {/* </div> */}


            {timerCompleted && <TimeOut />}
      {showPopup && <ExitPopup open={showPopup} setOpen={setShowPopup} missionId={missionId} />}

        </>
    )
}





export default Play