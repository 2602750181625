import React, { useEffect } from 'react'
// import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import Appbar from '../../../comman/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import Arrow from '../../../assets/icon/Arrow.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import CarouselImg from '../../../assets/img/image1.png'
import Food from '../../../assets/img/cat.png'
// import styles from '../../../css/screens/Subcategory.module.scss'
// import { getBannerAction } from '../../../redux/screen/banner';
import { getSubCategoryAction } from '../../../redux/screen/subcategory';
import LoadingUI from '../../../comman/LoadingUI'



export default function Subcategory() {
    // state
    const navigate = useNavigate()
    const location = useLocation()
    const sub_id = location.state.cat_id
    const catname = location.state.catname
    const dispatch = useDispatch()
    const subcategory = useSelector(state => state.getSubcategory)
    const { data: subcategoryData, loading: subcategoryLoading, status, message } = subcategory
    const getAppSelector = useSelector(state => state.getApp)
    const { data: { is_ads_enabled } } = getAppSelector

    if (status === 401) {
        alert(message)
    }


    // const { pathname } = useLocation()

    // const [activeTab, setActiveTab] = useState('survey');


    // use effect
    // useEffect(() => {
    //     if (pathname.includes("/survey")) {
    //         history.pushState(null, null, location.href);
    //         window.onpopstate = function () {
    //             history.go(1);
    //         };
    //     }
    // }, [])

    useEffect(() => {
        dispatch(getSubCategoryAction(sub_id))
    }, [dispatch])

    return (

        <>
            <div className="main_div_sub">

                <Appbar title='Select Subcategory' />

                <div className="banner_sub">

                    {/* <img className={styles.banner_img_top} src={CatImgT} /> */}
                    <img style={{ marginTop: "10px" }} src={Food} />
                    <p className="subcategory_heading" style={{ fontWeight: "900" }}>{catname}</p>

                </div>
                <div className='banner_sub_heading'>
                    <p>{catname}</p>
                </div>
                <div className='starting_text'>
                    <p>Choose your favourite genre</p>
                </div>
                <div className="categories_sec_sub">
                    {subcategoryLoading ? <LoadingUI /> : subcategoryData?.length > 0 ? (subcategoryData.map((item, index) => {
                        return (
                            <>
                                <div key={index} className="categorie_sub" onClick={() => { navigate('/missions', { state: { sub_cat_id: item._id, subcatname: item.sub_cat_name } }) }}>
                                    <div className="heading_sub">
                                        {item.sub_cat_name}
                                    </div>

                                    <div className="link_sub">
                                        View Quizzes &nbsp;
                                        <span><img src={Arrow} alt='arrow' /></span>
                                    </div>

                                </div>

                                {index % 2 !== 0 && is_ads_enabled && <div style={{ display: 'flex', width: "100vw", height: "20vh", alignItems: "center", justifyContent: "center", background: "#fff" }}>
                                    ads
                                </div>}
                            </>


                        )

                    })) : (<span style={{ display: "flex", justifyContent: "center", width: "100vw" }}><center>"Subcategory Not Available"</center></span>)}

                </div>

            </div>
        </>
    )
}