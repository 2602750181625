// import "./RadioComp.css";
import React, { useState } from "react";
function RadioComp({ getRadioData, options, setOptions, setScrollBottom }) {

    const [checkData, setCheckData] = useState(getRadioData)




    function choseChangeHandler(event) {
        setOptions(event.target.value)
        setScrollBottom(true)

    }


    //  console.log("props-->",props);
    //   props.getRadioData(checkData);


    return (
        <>
            <div className="input-radio">
                <div className="qus_heading" style={{ margin: "15px 0px", fontSize: "12px", fontWeight: "400", height: "100%", scrollY: "auto" }}>Choose the correct answers: </div>

                {checkData?.length > 0 ? (checkData.map((item, index) => {
                    return (

                        <div style={{ marginTop: "15px" }} key={index}>
                            <table>
                                <tr style={{width:"100vw"}}>
                                    <td style={{width:"10vw"}}>
                                        <input type="radio" id={item.option} name="mode" value={item.option} onChange={choseChangeHandler} />
                                    </td>
                                    <td style={{width:"80vw"}}>
                                        <label style={{ fontWeight: "600", fontSize: "18px",margin:"0px",padding:"0px" }} htmlFor={item.option}>{item.option}</label>
                                    </td>
                                </tr>
                            </table>
                            {/* <br /> */}
                        </div>



                    )


                })) : ("No Category Available")}
            </div>

        </>
    );
}
export default RadioComp;
