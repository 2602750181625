import { isVisible } from "@testing-library/user-event/dist/utils";
import Style from "./check.module.scss";
import React, { useState } from "react"
function Check({ getCheckBoxesData, options, setOptions,setScrollBottom }) {

  const [cdata, setCdata] = useState({ food: [] })
  const [checkData, setCheckData] = useState(getCheckBoxesData)
  

  function handleCheckboxChange(event) {
    const value = event.target.value;

    // window.scrollTo(0,10000)
    if (event.target.checked) {
      // Add the value to the array
      setOptions([...options, value]);
    } else {
      // Remove the value from the array  
      setOptions(options.filter(item => item !== value));
    }
    setScrollBottom(true)
  }
  // props.getCheckBoxesData(cdata);

  return (
    <div className={Style.check_boxes}>
      
      <div className={Style.qus_heading} style={{margin:"15px 0px",fontSize:"12px",fontWeight:"400",color:"#fff"}}>Choose all the correct answers: </div>

      {checkData?.length > 0 ? (checkData.map((item, index) => {
        return (

            
          <div>
            <input type="checkbox" id={item.option} name={item.option} value={item.option} onChange={handleCheckboxChange} />
          <label htmlFor={item.option} key={index} style={{background:"rgba(142, 84, 233, 0.17)",padding:"10px 20px",borderRadius:"14px",marginTop:"10px",display:"flex",alignItems:"center",}}>
           
            <p style={{fontWeight:"600",fontSize:"18px",lineHeight:"22px",color:"#fff"}} htmlFor={item.option}>{item.option}</p>
            
            <br />
          </label>
          </div>

          



        )


      })) : ("No Category Available")}


      <br></br>

    </div>


  )
}
export default Check