import React, { useEffect, useState } from 'react'
import Appbar from '../component/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import Points from '../../../../assets/icon/points.png'
import NA from '../../../../assets/icon/na.png'
import DailyStreak from '../../../../assets/img/daily_streak.png'
import SpinIcon from '../../../../assets/img/spinwheel_small.png'
import { getUserTransactionAction } from '../../../../redux/user/getUserTransaction'
import moment from 'moment'
import Style from './history.module.scss'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function History() {
    window.scrollTo(0, 0);
    const dispatch = useDispatch()

    const getUserTransaction = useSelector(state => state.getUserTransaction)
    const { loading } = getUserTransaction
    const [limit, setLimit] = useState(100)
    const [page, setPage] = useState(0)
    const [Data, setData] = useState([]);
    const [DataLength, setDataLength] = useState(0)
    const [userDeviceWidth,setUserDeviceWidth]=useState(null)



    useEffect(() => {
        // dispatch(getUserTransactionAction({ page: page,limit:limit }))
        // setData([...TransactionData])

        // const fetchData = async () => {
        //     try {
        //         let res = await dispatch(getUserTransactionAction({ page: page, limit: limit }));
        //         if (res?.payload?.status === 200) {
        //             setData(prevData => [...prevData, ...res?.payload?.data]);  
        //         }

        //     } catch (error) {
        //         console.log("Error:", error);
        //     }
        // };
        setUserDeviceWidth(window.innerWidth)
        nextHandler();

    }, [])

    const nextHandler = async () => {

        setPage((prevPage) => {
            return prevPage + 1;
        });


        let response = await dispatch(getUserTransactionAction({ page: page, limit: limit }))
        if (response?.payload?.status === 200) {
            setData([...Data, ...response?.payload?.data]);
            setDataLength(response?.payload?.totalLength)

        }

    }

    return (
        <>
            <Appbar />

            <div className={Style.main_div}>



                <div className={Style.history__heading}>
                    <h3>Quiz History</h3>
                    {/* <div className={Style.view_all}>
                        <p><Link className={Style.link} to={'/history'}>View all</Link></p>
                    </div> */}

                </div>

                {loading ?<div style={{display:'flex',alignItems:"center",justifyContent:"center"}}> <Box sx={{ width: "80vw ",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column" }}>

<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
<br />
<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
<br />
<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
<br />
<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />


</Box> </div>: Data.length > 0 ? Data.map((item, index) => (

                    (item?.mission_id || item?.reward_type == 2 || item?.reward_type == 3) && (
                        <div className={Style.history__card} key={index}>
                            <div className={Style.history__pic}>
                                {item?.mission_id && <img src={item?.mission_id?.icon ? item?.mission_id?.icon : NA} alt="icon" />}
                                {item?.reward_type === 2 && <img src={DailyStreak} alt="icon" />}
                                {item?.reward_type === 3 && <img src={SpinIcon} alt="icon" />}
                            </div>
                            <div className={Style.history__details}>
                                <div className={Style.top}>
                                    {/* {item?.mission_id && <div className={Style.name}>{(item?.mission_id?.title.length>11 && userDeviceWidth>450) ? item?.mission_id?.title.substring(0, 11)+'...' : item?.mission_id?.title.substring(0, 18)}</div>} */}
                                    {item?.mission_id && <div className={Style.name}>{(item?.mission_id?.title.length>11 && userDeviceWidth<360) ? item?.mission_id?.title.substring(0, 11)+"..." : (item?.mission_id?.title.length>14 && userDeviceWidth<400) ? item?.mission_id?.title.substring(0, 14)+"..." : item?.mission_id?.title.substring(0, 18)}</div>}

                                    {item?.reward_type === 2 && <div className={Style.name}>{'Daily Login'}</div>}
                                    {item?.reward_type === 3 && <div className={Style.name}>{'Spinwheel'}</div>}
                                    <div className={Style.logo}>
                                        <div className={Style.logo_pic}>
                                            <img src={Points} width="18px" alt="logo" />
                                        </div>
                                        <div className={Style.logo_name}>
                                            <p style={{ fontSize: "16px" }}>{item?.reward_amt.toPrecision(4)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={Style.middle}>{item?.mission_id?.cat_id?.cat_name ? item?.mission_id?.cat_id?.cat_name : 'Daily Reward'}</div>

                                <div className={Style.bottom}>
                                    <div>
                                        Completed On:&nbsp;{moment.utc(item.created_at).format("Do MMM YY")}
                                    </div>
                                    <div>
                                        |
                                    </div>
                                    <div>
                                        {moment.utc(item.created_at).format('h:mm a')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : <div className={Style.no__transaction}><h3>No transaction history available</h3></div>}
                {/* <div className='bg_color bg_color_history' style={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: 'center' }}>

                {loading ? 'Loading...' : Data?.length > 0 ? (
                    Data.map((item, index) => (
                        (item?.mission_id || item?.reward_type==2 || item?.reward_type==3)  && (
                            <div className="history__card" key={index}>
                                <div className="history__pic">
                                {item?.mission_id && <img src={item?.mission_id?.icon ? item?.mission_id?.icon : NA} alt="icon" />}
                                {item?.reward_type===2 && <img src={DailyStreak} alt="icon" />}
                                {item?.reward_type===3 && <img src={SpinIcon} alt="icon" />}
                                </div>
                                <div className="history__details">
                                    <div className="top">
                                        {item?.mission_id && <div className="name">{item?.mission_id?.title ? item?.mission_id?.title.substring(0, 18) : '---'}</div>}
                                        {item?.reward_type===2 && <div className="name">{'Daily Login'}</div>}
                                        {item?.reward_type===3 && <div className="name">{'Spinwheel'}</div>}
                                        <div className="logo">
                                            <div className="logo_pic">
                                                <img src={Points} width="20px" alt="logo" />
                                            </div>
                                            <div className="logo_name">{item.reward_amt}</div>
                                        </div>
                                    </div>
                                    <div className="middle">{item?.mission_id?.cat_id?.cat_name ? item?.mission_id?.cat_id?.cat_name : 'Daily Reward'}</div>
                                    <div className="bottom">
                                        <div>Completed On: {moment.utc(item.created_at).format("Do MMM YY")}</div>
                                        <div>|</div> 
                                        <div>{moment.utc(item.created_at).format('h:mm a')}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))
                ) : (
                    <div className='no__transaction__history'><h3>No transaction history available</h3></div>
                )}


                {Data.length < DataLength && <div className='next__page'><p onClick={nextHandler}>View More <span>&rarr;</span></p></div>}
            </div> */}

            </div>



        </>
    )
}

export default History