import "./AudioComp.css"
import React, { useEffect } from "react"

function AudioComp({ audio }) {
  useEffect(()=>{
    function enableAutoplay() { 
      document.querySelector("audio").play();
    }
    enableAutoplay()
  },[])
  return (
    

      <div className="video_camp" >
        <div className="video-container">
          

          <audio id='audio' src={audio} autoplay controls></audio>

          
          </div>
      </div>

    



  )
}
export default AudioComp