// import "./VideoComp.css"
import React, { useEffect } from "react"

function VideoComp({ video }) {
useEffect(()=>{
  function enableAutoplay() { 
    document.querySelector("video").play();
  }
  enableAutoplay()
},[])
  
  return (
    

      <div className="video_camp" >
        <div className="video-container">
          <video id="video" src={video} autoplay controls></video>

          </div>
      </div>

    



  )
}
export default VideoComp