
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
// import "./Timer.css";
import React, { useEffect, useState ,forwardRef,useImperativeHandle} from "react";
import timeIcon from "../../../assets/icon/stopwatch.png"
import TimerSharpIcon from '@mui/icons-material/TimerSharp';


function Timer({ next, indexValue, length,second,timer}) {
  // const [seconds, setTime] = useState(timer*60);
  const [minutes, setMinutes] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  // const [timers,setTimers]=useState(timer*60)

  // propPassed()
  // console.log(timer,"timer")
  

//   useEffect(() => {
//         const interval = setInterval(() => {
//           setTimers(prevSeconds => prevSeconds - 1);
//         }, 1000);

//         return () => clearInterval(interval);
// }, [timers]);
 

  return (
    <div >
      {/* <div className="pau_icon" >
        
       {!isRunning  ? <PlayArrowIcon onClick={playHandler} /> :<PausePresentationIcon onClick={pauseHandler} /> }
      
      </div> */}
      

     {timer && <div className="timer">
        {/* {minutes < 10 ? "0" + minutes : minutes}: */}
        <TimerSharpIcon/>&nbsp; : &nbsp;
        {timer && timer}
      </div>}
    </div>
  );
}
export default Timer;
