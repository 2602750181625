import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import countassest from '../../../../assets/theme6/count.svg'
import Style from './start.module.scss'
import CountAnimation from '../Animation/countAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import ExitPopup2 from '../../../user/qna/ExitPopup2';
// import LayoutComp from './LayoutComp'
import { getQnaAction } from '../../../../redux/screen/getqna'
import Play from './Play'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

// import Timer from './Timer'



const Start = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location=useLocation()
    const user_id = JSON.parse(localStorage.getItem("pwa_user_id"));
    const search = useLocation().search;
    const mission_id = new URLSearchParams(search).get('mission_id');
    const time_criteria = new URLSearchParams(search).get('time_criteria');
    const reward = new URLSearchParams(search).get('reward');
    const [missionId,setMissionId]=useState(mission_id)
    const [timerCriteria,setTimeCriteria]=useState(time_criteria)
    const [rewards,setRewards]=useState(reward)



    

    const [showPopup, setShowPopup] = useState(false);
   



    const getQna = useSelector(state => state.getQna)
    const { data: qnaData, loading: qnaLoading } = getQna




    const [count, setCount] = useState(3)
   


    // useEffect(() => {
    //     const handlePopstate = () => {
    //       // Handle browser back/forward navigation here
    //     //   console.log('Browser navigation detected');
    //       navigate('/home')
    //     };
    
    //     // Add event listener for popstate
    //     window.addEventListener('popstate', handlePopstate);
    
    //     return () => {
    //       // Clean up: remove event listener when component unmounts
    //       window.removeEventListener('popstate', handlePopstate);
    //     };
    //   }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          // Custom logic to handle the refresh
          // Display a confirmation message or perform necessary actions
          alert("hello world")

        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (count > 0) {
                setCount(count - 1); // Decrease count by 1
            }else {
                clearInterval(intervalId); // Clear the interval when count reaches 1
            }
        }, 1000); // Repeat every 1000 milliseconds (1 second)

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [count]);


    


    useEffect(() => {
        dispatch(getQnaAction({
            "mission_id": missionId,
        }))
    }, [dispatch])




    useEffect(() => {
       

        window.location.hash ="no-back-button";
            window.location.hash = "Again-No-back-button";
            window.onhashchange = function () {
                window.location.hash = "no-back-button";
            }
        
    }, [])

   

    return (
        <>
            <Layout missionId={mission_id}>
                <div className={Style.container}>
                   {count!=0?
                   <div className={Style.count__div}>
                        <div className={Style.count__number}>
                            {count<4 && count}
                        </div>

                        <img src={countassest} alt="count" />
                        <div className={Style.animation}>
                           {count<4 && <CountAnimation />}

                        </div>
                    </div>:
                    <Play timer={<Timer clock={timerCriteria}/>} time_criteria={timerCriteria} reward={rewards} qnaData={qnaData}/>}
                </div>

           {/* {showPopup && <ExitPopup2 state={showPopup} setState={setShowPopup}/>} */}

            </Layout>
        </>
    )
}


const Timer = ({ clock }) => (
    <>
        <CountdownCircleTimer
            isPlaying
            duration={clock*60}
            initialRemainingTime={clock*60}
            size={60}
            strokeWidth={5}
            colors={'#FF1C00'}
            rotation={'counterclockwise'}

        >
            {({ remainingTime }) => (
                <div style={{ color: '#FF1C00',fontWeight:"700" }}>
                    {`${remainingTime}s`}
                </div>


            )}

        </CountdownCircleTimer>

    </>
)
export default Start    