import React, { useEffect } from 'react'
// import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import Appbar from '../component/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import Arrow from '../../../../assets/theme6/Arrow.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Food from '../../../../assets/img/cat.png'

import { getSubCategoryAction } from '../../../../redux/screen/subcategory';
import LoadingUI from '../../../../comman/LoadingUI'
import Style from '../style/subcategory.module.scss'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';




export default function Subcategory() {
  // state
  const navigate = useNavigate()
  const location = useLocation()
  const sub_id = location.state.cat_id
  const catname = location.state.catname
  const dispatch = useDispatch()
  const subcategory = useSelector(state => state.getSubcategory)
  const { data: subcategoryData, loading: subcategoryLoading, status, message } = subcategory
  const getAppSelector = useSelector(state => state.getApp)
  const { data: { is_ads_enabled } } = getAppSelector


  if (status === 401) {
    alert(message)
  }


  // const { pathname } = useLocation()

  // const [activeTab, setActiveTab] = useState('survey');


  // use effect
  // useEffect(() => {
  //     if (pathname.includes("/survey")) {
  //         history.pushState(null, null, location.href);
  //         window.onpopstate = function () {
  //             history.go(1);
  //         };
  //     }
  // }, [])

  

  useEffect(() => {
    dispatch(getSubCategoryAction(sub_id))
  }, [dispatch])

  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: smooth scrolling animation
});

  return (

    <>

        <Appbar />
      <div className={Style.main_div_sub}>

        <div className={Style.banner_sub}>

          {/* <img className={styles.banner_img_top} src={CatImgT} /> */}
          <img style={{ marginTop: "10px" }} src={Food} />
          <p className={Style.subcategory_heading} style={{ fontWeight: "900" }}>{catname}</p>

        </div>
        <div className={Style.banner_sub_heading}>
          <p>{catname}</p>
        </div>
        <div className={Style.starting_text}>
          <p>Choose any of your favorite genre and get started.</p>
        </div>
        <div className={Style.categories_sec_sub}>
          {subcategoryLoading ?  <>


<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"48%"} height={170} />

<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"48%"} height={170} />


<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"48%"} height={170} />

<Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"48%"} height={170} />


</>  : subcategoryData?.length > 0 ? (subcategoryData.map((item, index) => {
            return (
              <>
                <div key={index} className={Style.categorie_sub} onClick={() => { navigate('/missions', { state: { sub_cat_id: item._id, subcatname: item.sub_cat_name } }) }}>
                  <div style={{marginBottom:"30px"}}>
                  <div className={Style.heading_sub}>
                    {item.sub_cat_name}
                  </div>
                  </div>

                  <div className={Style.link_sub}>
                    <p>View Quizzes</p>
                    <span><img src={Arrow} alt='arrow' /></span>
                  </div>

                </div>

                {index % 2 !== 0 && is_ads_enabled && <div style={{ display: 'flex', width: "100vw", height: "20vh", alignItems: "center", justifyContent: "center", background: "#fff" }}>
                  ads
                </div>}
              </>


            )

          })) : (<span style={{ display: "flex", justifyContent: "center",alignItems:'center',height:"80vh",width: "87vw",color:"#fff", }}>Subcategory Not Available</span>)}

        </div>

      </div>
    </>
  )
}