import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { exitMissionAction } from '../../../../redux/screen/exitmission';
import Logo from '../../../../assets/icon/favicon.png'
import Styles from './LimitPopup2.module.scss'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LimitPopup({open,setOpen}) {
const dispatch=useDispatch()
const navigate=useNavigate()


 

  const handleClose = () => {
    setOpen(false);
  };


  return (
    // <div>
      
    //   <Dialog
    //     open={open}
    //     TransitionComponent={Transition}
    //     keepMounted
    //     onClose={handleClose}
    //     aria-describedby="alert-dialog-slide-description"
    //     className='mobile__width'
    //   >
    //     <DialogTitle style={{display:"flex",justifyContent:"center"}}><img src={Logo} width="70px"/></DialogTitle>
    //     <DialogContent>
    //       <DialogContentText id="alert-dialog-slide-description" style={{textAlign:"center",fontFamily:"Roboto!important"}}>
    //       You have exceeded your daily limit for Quiz completion. Please come back again after 12:00 A.M.
    //       </DialogContentText>
    //     </DialogContent>
        
    //     <DialogActions className="dailogue_bg" style={{display:"flex",justifyContent:"center"}} onClick={handleClose} >
    //       <Button  className="dailogue_bg"  variant='contained' >Okkkkkkkkkkkkkkk</Button>
    //     </DialogActions>
    //   </Dialog>
    // </div>
    <div
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    className={Styles.parent}

>



    {/* <DialogTitle style={{display:"flex",justifyContent:"center"}}><img src={Logo} width="70px"/></DialogTitle>
<DialogContent>
  <DialogContentText id="alert-dialog-slide-description" style={{textAlign:"center",fontFamily:"Roboto!important"}}>
  You have exceeded your daily limit for this Mission completion. Please come back again after 12:00 A.M. or Try Other Mission
  </DialogContentText>
</DialogContent>*/}
    <div className={Styles.main}>
        {/* <DialogActions className="dailogue_bg" style={{ display: "flex", justifyContent: "center" }} onClick={handleClose} > */}

        <h2 className={Styles.heading}>OOPS!!</h2>
        <div>
        <p className={Styles.para}>
        You have exhausted your daily limit for quiz <br/> completion.  Please come back again <br/>after 12:00 A.M.

              {/* ". Please come back again after 12:00 A.M." */}

        </p>
        </div>


        {/* </DialogActions> */}

        <button className={Styles.btn} onClick={() => { setOpen(false) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
        <path d="M14 5.83832L5.17647 1.60303M12.2353 10.7795L2 10.7795M14 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
    </svg><p>Okk</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M2 5.83832L10.8235 1.60303M3.76471 10.7795L14 10.7795M2 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
        </svg></button>

    </div>
    {/* <div> */}
    
    

</div>
  );
}
