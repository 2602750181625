// import "./ImageComp.css"
import React from "react"
import { useNavigate } from 'react-router-dom';


function ImageComp({image}) {

  // console.log('checking image ===>', image)

  return (
    <div id='image__div' style={{width:"100%",height:"35%",marginBottom:"1px!important",paddingBottom:"1px!important"}}>
      <img id="qns_image"  src={image} alt="question_image"/>
    </div>
  )
}
export default ImageComp