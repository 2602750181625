import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import timeout from '../../../assets/icon/timeup.png'




export default function ExitPopup2(showPopup, setShowPopup) {
  const navigate = useNavigate()
  setTimeout(()=>{
      navigate('/home')
  },[3000])

  return (
    <div>
      <Modal
        open={showPopup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       

        <div className='timepopup'>
          
          <div className="timepopup_child" style={{ width: "70vw", height: "35vh", background: "#fff", display: "flex", alignItems: "center", justifyContent: "center", gap: "30px", flexDirection: "column", borderRadius: "10px", padding: "30px" }}>
            <img src={timeout} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "20px" }}>
              <h2>Timeout!!</h2>
              <center>Time's up!
                Your mission has timed out.
                Please try again.</center>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
}