import React, { useEffect, useState } from 'react'
import Appbar from '../../comman/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import Points from '../../assets/icon/points.png'
import NA from '../../assets/icon/na.png'
import DailyStreak from '../../assets/img/daily_streak.png'
import SpinIcon from '../../assets/img/spinwheel_small.png'
import { getUserTransactionAction } from '../../redux/user/getUserTransaction'
import moment from 'moment'

function History() {
    window.scrollTo(0, 0);
    const dispatch = useDispatch()   

    const getUserTransaction = useSelector(state => state.getUserTransaction)
    const { loading } = getUserTransaction
    const [limit, setLimit] = useState(8)
    const [page, setPage] = useState(0)
    const [Data, setData] = useState([]);
    const [DataLength, setDataLength] = useState(0)



    useEffect(() => {
        // dispatch(getUserTransactionAction({ page: page,limit:limit }))
        // setData([...TransactionData])

        // const fetchData = async () => {
        //     try {
        //         let res = await dispatch(getUserTransactionAction({ page: page, limit: limit }));
        //         if (res?.payload?.status === 200) {
        //             setData(prevData => [...prevData, ...res?.payload?.data]);  
        //         }

        //     } catch (error) {
        //         console.log("Error:", error);
        //     }
        // };

        nextHandler();

    }, [])

    const nextHandler = async () => {

        setPage((prevPage) => {
            return prevPage + 1;
        });


        let response = await dispatch(getUserTransactionAction({ page: page, limit: limit }))
        if (response?.payload?.status === 200) {
            setData([...Data, ...response?.payload?.data]);
            setDataLength(response?.payload?.totalLength)

        }

    }

    return (
        <>
            <Appbar title='History' />

            <div className='bg_color bg_color_history' style={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: 'center' }}>

                {loading ? 'Loading...' : Data?.length > 0 ? (
                    Data.map((item, index) => (
                        (item?.mission_id || item?.reward_type==2 || item?.reward_type==3)  && (
                            <div className="history__card" key={index}>
                                <div className="history__pic">
                                {item?.mission_id && <img src={item?.mission_id?.icon ? item?.mission_id?.icon : NA} alt="icon" />}
                                {item?.reward_type===2 && <img src={DailyStreak} alt="icon" />}
                                {item?.reward_type===3 && <img src={SpinIcon} alt="icon" />}
                                </div>
                                <div className="history__details">
                                    <div className="top">
                                        {item?.mission_id && <div className="name">{item?.mission_id?.title ? item?.mission_id?.title.substring(0, 18) : '---'}</div>}
                                        {item?.reward_type===2 && <div className="name">{'Daily Login'}</div>}
                                        {item?.reward_type===3 && <div className="name">{'Spinwheel'}</div>}
                                        <div className="logo">
                                            <div className="logo_pic">
                                                <img src={Points} width="20px" alt="logo" />
                                            </div>
                                            <div className="logo_name">{item.reward_amt}</div>
                                        </div>
                                    </div>
                                    <div className="middle">{item?.mission_id?.cat_id?.cat_name ? item?.mission_id?.cat_id?.cat_name : 'Daily Reward'}</div>
                                    <div className="bottom">
                                        <div>Completed On: {moment.utc(item.created_at).format("Do MMM YY")}</div>
                                        <div>|</div> 
                                        <div>{moment.utc(item.created_at).format('h:mm a')}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))
                ) : (
                    <div className='no__transaction__history'><h3>No transaction history available</h3></div>
                )}


                {Data.length < DataLength && <div className='next__page'><p onClick={nextHandler}>View More <span>&rarr;</span></p></div>}
            </div>



        </>
    )
}

export default History