import React,{useState} from 'react';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import timeout from '../../../assets/icon/collect.png'
import authAxios from '../../../config/authAxios';






export default function StreakPopup({popup, setpopup}) {
  const navigate = useNavigate()
  const [isCollect,setIsCollect]=useState(false)
  // setTimeout(()=>{
  //     navigate('/home')
  // },[3000])
const beforecollectHandler=()=>{
  setIsCollect(true)
  collectHandler()
}
  const collectHandler=async()=>{
      const res=await authAxios.post('users/api/mission/dailyreward',{reward_type:2})
      // console.log("res-->",res);
      setTimeout(()=>{
        setpopup(false)
      },1000)
  }

  return (
    <div>
      <Modal
        open={popup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       

        <div className='timepopup'>
          
          <div className="timepopup_child" style={{ width:"80vw",background:"#fff", display: "flex", alignItems: "center", justifyContent: "center", gap: "30px", flexDirection: "column", borderRadius: "10px", padding: "30px" }}>
            <img src={timeout} width="100px" />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "20px" }}>
              <h2>Yay!</h2>
              <center>
              {isCollect?<h3>Your Daily reward added to you Wallet</h3>:<h3>Your Daily reward is ready</h3>}
              </center>
              {!isCollect && <button className='collect__btn' onClick={()=>beforecollectHandler()}>Collect</button>}
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
}