import React, { useState, useRef, useEffect, useContext } from 'react';
import authAxios from '../../../config/authAxios';
import Appbar from '../../../comman/Appbar';
import { useDispatch,useSelector } from 'react-redux'


// image
// import { ReactComponent as Arrow } from "../../assets/newImages/arrow.svg"
import { ReactComponent as Coin } from "./Coin.svg"

import Wheel from "../../../assets/img/spinwheel_bg.png"
// import { DrawerContext } from '../Context/DrawerProvider';



function SpinWheel() {
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data } = userDetialsSelector
    const [styleChange, setStyleChange] = useState(false);
    // const { setRewardClose, walletToggle, rewardClose, setWalletToggle } = useContext(DrawerContext)
    const [rewardClose,setRewardClose]=useState(false);
    const [walletToggle,setWalletToggle]=useState(false);
    const [rotation, setRotation] = useState(0);
    const [chances, setChances] = useState(0);
    const [spins, setSpins] = useState({ received: 0, used: 0 })
    const [winning, setWinning] = useState(0)
    const [tap, setTap] = useState(true)
    const containerRef = useRef(null);
    const arrowRef = useRef(null);

    const UserDetails = () => {
        authAxios
            .get(`/users/api/my-details`)
            .then((res) => {
                const data = res.data.data
                // setChances(data.spinData.received - data.spinData.used)
                // setWinning(data.todayWin)
                // setSpins(data.spinData)
                
                // console.log("data world-->",data);
                if(data?.spin_earn){
                    setChances(data?.spin_earn-data?.spin_used)
                }else{
                    setChances(1)
                }
    
                if(data?.spin_used){
                    setSpins(data?.spin_used)
                }else{
                    setSpins(0)
                }
                if(data?.spin_used){
                    setWinning(data?.spin_winning)
                }else{
                    setWinning(0)
                }
            }).catch((err) => {
                console.log("err", err)
            })
            

                
                // setWinning(2)

        
    }

    const [Finished, setFinished] = useState(false)
    // Counter clockwise
    const symbolSegments = {
        1: "0",
        2: "5",
        3: "0",
        4: "4",
        5: "0",
        6: "3",
        7: "0",
        8: "2",
        9: "0",
        10: "1",
    }
    let deg = 0
    const spinStart = () => {
        if (chances > 0) {
            setTap(false)
            setRewardClose(true)
            deg = Math.floor(5000 + Math.random() * 5000) + 90;
            setRotation(deg + rotation);
            setStyleChange(true);
            setTimeout(() => {
                handleTransitionEnd(deg + rotation)
                setFinished(true)
            }, 10000)
        }
    }
    // console.log("rotation", rotation)


    const [WinningAmount, setWinningAmount] = useState(0)

    const handleTransitionEnd = async (updatedRotation) => {
        const actualDeg = updatedRotation % 360;
        // console.log("actualDeg", actualDeg)
        deg = 0
        handleWin(actualDeg);
    };
    const handleWin = async (actualDeg) => {
        const winningSymbolNr = Math.ceil(actualDeg / Number(36));
        setWinningAmount(symbolSegments[winningSymbolNr])

        // console.log("winningamoount-->",WinningAmount)

        await authAxios
            .post(`users/api/mission/spinwheel`, {
                spinAmount: Number(symbolSegments[winningSymbolNr])
            })
            .then((res) => {
                if (res.data.status === true) {
                    localStorage.setItem("userData", JSON.stringify(res.data.user));
                    setWalletToggle(!walletToggle)
                }
                UserDetails();
            })

    }
    useEffect(() => {
        if (Finished) {
            setTimeout(() => {
                setFinished(false)
                setTap(true)
                setRewardClose(false)

            }, 3000)
        }
    }, [Finished])
    useEffect(() => {
        UserDetails()
    }, []);
    // stop going back in page
    const handledisablegoingback = () => {
        window.history.pushState(null, document.title, window.location.href);
    };
    useEffect(() => {
        if (rewardClose) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener("popstate", handledisablegoingback);
        }
        return () => { window.removeEventListener("popstate", handledisablegoingback) };
    }, [rewardClose]);
    return (
        <>
            <Appbar/>
            <div className="spinCover">
                <div className="spinBox">
                    {
                        chances > 0 && tap ?
                            <div className='clickSpin' onClick={() => spinStart()}>Click To Spin</div> :
                            ""
                    }
                    <span className='winning_amount'>{WinningAmount}</span>
                    {
                        <div id={!Finished ? 'spin' : 'spinEnd'}>
                            <Coin/>
                            <span className='winning_amount'>{WinningAmount}</span>
                        </div>
                    }

                    <span ref={arrowRef} className="arrow"><svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22L0.942633 0.249998L26.0574 0.25L13.5 22Z" fill="#130088"></path></svg></span>
                    <img className={`wheel ${styleChange ? ' slow-down' : ''}`} src={Wheel} alt='wheel' ref={containerRef} style={{ transform: `rotate(${rotation}deg)` }} />
                </div>
                <div className='todayWin'><p>Today's Winning : {winning} <Coin className='coinSv' /></p> </div>
                <div className='spinRemai'><p>Spins Earned : {data?.spin_earn}</p><p>Spins Used : {data?.spin_used}</p></div>
                {/* <p className='notingText'>Get 1 free spin daily and Complete a task of minimum 40 coins to earn a spin </p> */}
            </div>
        </>
    );
}

export default SpinWheel;